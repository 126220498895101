
  import {
    reactive,
    ref,
    SetupContext,
    defineComponent,
    onMounted,
    PropType
  } from "vue";
  import { CalendarOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
  import {
    ConfirmChangeExaminerModel,
    PageResultOfListOfChangeExaminerAvailabilityLogs,
  } from "@/api-client/client";
  import { _Client } from "@/api-client";
  import Pagination from "@/components/Pagination/index.vue";
  import dayjs from "dayjs";
  
  export default defineComponent({
    props: {
        examinerId: {
        type: String as PropType<string>,
        required: true,
      },
      examiningDate: {
        type: Number  as PropType<Number  | null>,
        required: true,
      },
    },
    components: {
      Pagination,
      CalendarOutlined,
      ExclamationCircleOutlined,
    },
    setup(props: { [key: string]: any }, context: SetupContext) {
      function close(refresh: boolean = false) {
        context.emit("close", refresh);
      }
      const columns = [
        {
          title: "Create By",
          dataIndex: "createdBy",
          width: 250
        },
        {
          title: "Create On",
          dataIndex: "createdOn",
          width: 200
        },
        {
          title: "Log Detail",
          dataIndex: "logDetail",
        
        },
      ];
  
      const dataSource = reactive<PageResultOfListOfChangeExaminerAvailabilityLogs>(
        new PageResultOfListOfChangeExaminerAvailabilityLogs()
      );
      const searchParames = reactive<ConfirmChangeExaminerModel>(
        new ConfirmChangeExaminerModel({
          pageIndex: 1,
          pageSize: 10,
          examinerId: props.examinerId,
          examiningDate : props.examiningDate
        })
      );
      const tableLoading = ref(false);
  
      const getList = () => {
         
        tableLoading.value = true;
        _Client.changeExaminerAvailabilityClient
          .getLogList(searchParames)
          .then((rep) => {
            dataSource.init(rep);
          })
          .finally(() => (tableLoading.value = false));
      };
  
      onMounted(async () => {
        
        getList();
      });
  
      return {
        columns,
        dataSource,
        searchParames,
        tableLoading,
        getList,
        close,
        dayjs,
      };
    },
  });
  